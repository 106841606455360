import React from "react"
import {Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"

const ComboFlynow = () => {
  const data = {
    id: "combo_app_ebook",
    title: "PREMIUM VITALÍCIO + EBOOK PRODUTIVIDADE",
    discontPercentage: 20,
    URL_CHECKOUT: "https://buy.stripe.com/5kA5m715T3hPdZC9AO?locale=pt-BR&prefilled_promo_code=FLYN20",
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVO (PLANO PREMIUM VITALÍCIO)",
        data: [Products.apps[0]]
      },
      {
        section: "EBOOK",
        data: [Products.ebooks[0]]
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow

